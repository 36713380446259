/*********** General Classes *************/
.HeaderElements ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.HeaderElements ul li {
  display: inline-block;
  width: 33.3%;
}
.HeaderElements ul li .backgroundLogo {
  max-width: 140px;
}
.HeaderElements ul li img {
  max-width: 100%;
}
.HeaderElements ul li h1 {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 26px;
  position: relative;
  bottom: 12px;
}
.HeaderElements ul li h2 {
  float: right;
  font-size: 30px;
  font-weight: 500;
  position: relative;
  bottom: 10px;
}
.Polaris-DataTable__Cell--numeric {
  text-align: left;
}
.stage {
  height: 650px;
  width: 99%;
  background: #fbfcfd;
  float: right;
  margin-top: 20px;
  margin-bottom: 40px;
  -webkit-box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  position: relative;
}
.stageBackground {
  float: right;
  width: 47%;
  max-height: 570px;
  max-width: 784px;
  position: absolute;
  bottom: 10%;
  right: 5%;
}
h3 {
  font-weight: bold;
}
.listButton {
  position: relative;
  padding-top: 7%;
  padding-left: 5%;
  padding-bottom: 7%;
}
/*********** Diagram Page *************/
.category-selector .Polaris-TextField {
  margin: 1em 1em 1em 0;
}
.Update_Diagram_page {
  float: left;
  height: 35px;
  width: 224px;
  cursor: pointer;
  background: transparent;
  padding: 5px 0;
  position: relative;
  transition: 0.5s;
  position: absolute;
  bottom: -5px;
  cursor: pointer;
}
.Update_Diagram_page label {
  color: transparent;
  text-align: left;
  position: absolute;
  left: 50px;
  font-size: 15px;
  font-weight: 500;
  top: 7px;
  -webkit-transition: 0.8s;
 /* Safari */
  transition: 0.8s;
  cursor: pointer;
}
.Update_Diagram_page input {
  opacity: 0;
  z-index: 10;
  position: absolute;
  cursor: pointer;
}
.fakeInput {
  width: 33px;
  height: 27px;
  display: inline-block;
  background: url(./icon-camera.png) no-repeat;
  position: absolute;
  left: 5px;
  -webkit-transition: 0.8s;
 /* Safari */
  transition: 0.8s;
  left: 10px;
  top: 7px;
  cursor: pointer;
}
/*********** Component List Products *************/
.listProductsTable tbody td {
  height: 90px !important;
}
.listProductsTable .Polaris-Page {
  max-width: 100% !important;
  padding: 0;
}
.listProductsTable .Polaris-DataTable__Cell {
  text-align: center !important;
}
.listProductsTable thead tr th {
  padding-bottom: 18px !important;
  height: 55px !important;
  width: 30%;
}
.listProductsTable thead tr th:nth-child(3) {
  width: 25%;
}
.listProductsTable tbody tr th {
  width: 30%;
}
/*********** Component List Diagram *************/
.statusPublished {
  width: 110px;
  text-align: center;
  line-height: 20px;
  color: #222;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  font-size: 13px;
  margin: auto;
}
.statusPublished span {
  width: 11px;
  height: 11px;
  display: inline-block;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
}
.isPublished {
  background: #bbe5b3;
}
.isNotPublished {
  background: #ffc58b;
}
.Last-Update {
  margin-top: 5px;
}
.Last-Update span {
  font-weight: bold;
}
.Polaris-Button__Icon:last-child {
  margin-left: 0;
}
.Polaris-Button__Icon:last-child {
  margin-left: 0;
}
.figure {
  margin: 0 auto;
  width: 850px;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
}
.overFlow {
  overflow: hidden !important;
}
.partListing .Polaris-ResourceList-Item:active {
  background: transparent;
  background-image: none;
}
.partListing .Polaris-ResourceList-Item--focused {
  background-image: none;
}
.partListing .Polaris-ResourceList-Item:hover {
  background-image: none;
  cursor: inherit;
}
.addPartsListing {
  min-height: 884px;
}
