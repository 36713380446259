.thumbnail {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
  height: 80px;
  padding-top: 30px;
  background: #919eab;
  opacity: 0;
  z-index: 30; 
}

.thumbnail:hover {
  opacity: 0.7;
}